import React, { useState, useEffect } from 'react';

const BulletListWithNotes = ({regenNotes, setRegenNotes}) => {
    const [bulletPoints] = useState([
        "The question does not make sense.",
        "None of the given answers are correct.",
        "The question is missing answers.",
        "The question is too difficult.",
        "The question is too easy.",
        "The question's code does not produce the correct figure."
    ]);

    const [selectedIndices, setSelectedIndices] = useState([]);

    const handleCheckboxChange = (index) => {
        setSelectedIndices(prev => {
            if (prev.includes(index)) {
                return prev.filter(i => i !== index);
            } else {
                return [...prev, index];
            }
        });
    };

    const handleNoteChange = (event) => {
        setRegenNotes(prev => ({
            ...prev,
            notes: event.target.value
        }));
    };

    useEffect(() => {
        setRegenNotes(prev => ({
            ...prev,
            selectedItems: selectedIndices.map(index => bulletPoints[index])
        }));
    }, [selectedIndices, bulletPoints]);

    return (
        <div>
            {bulletPoints.map((point, index) => (
                <div key={index} className="bullet-point">
                    <input
                        type="checkbox"
                        id={`checkbox${index}`}
                        checked={selectedIndices.includes(index)}
                        onChange={() => handleCheckboxChange(index)}
                    />
                    <label
                        htmlFor={`checkbox${index}`}
                        className={selectedIndices.includes(index) ? 'selected' : ''}
                    >
                        {point}
                    </label>
                </div>
            ))}
            <textarea
                rows="4"
                cols="50"
                placeholder="Enter your notes here..."
                value={regenNotes.notes}
                onChange={handleNoteChange}
            />
        </div>
    );
};

export default BulletListWithNotes;