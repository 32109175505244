const GenericAsync = async ({ backendUrl, dataToSend, jsonify= true }) => {

    try {
        const response = await fetch(backendUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        if (!response.ok) {
            throw new Error(`Network response was not ok: ${response.status}`);
        }
        if (jsonify) {
            return await response.json();
        } else {
            return await response
        }

    } catch (error) {
        throw error;
    }
};


export default GenericAsync;