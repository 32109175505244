import React, {useEffect, useState} from 'react';
import '../css/Style.css';
import GenericAsync from "../backend/GenericAsync";
import { useAuth0 } from '@auth0/auth0-react';
import MathJaxWrapper from '../utils/MathJaxWrapper';
import NavigationConsole from "./NavigationConsole";
import { useNavigate } from 'react-router-dom';
import ImageModal from "../modals/ImageModal";
import areObjectsEqual from "../utils/CheckDictionaryEquality"
import GenericModal from "../modals/GenericModal";
import {returnFormattedProblem} from "../utils/TextRender";
import EditableProblem from "../utils/EditableProblem";
import ImageGenerator from "../utils/ImageGenerator";
import DraftQuestionModal from "../modals/DraftQuestionModal";
import BulletListWithNotes from "../utils/BulletPointWithNotes";


const ProblemReview = ({problems, setProblems, existingProblems, draftProblems, navBarIndices, selection}) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const {user} = useAuth0();
    const navigate = useNavigate();

    const [problemIx, setProblemIx] = useState(0);
    const [editableProblem, setEditableProblem] = useState("");
    const [savedProblemDict, setSavedProblemDict] = useState({});
    const [markedForReeval, setMarkedForReeval] = useState({
        "accepted": {},
        "rejected": {},
    });

    const [isNavConsoleVisible, setIsNavConsoleVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [rawFormatting, setRawFormatting] = useState(false);
    const [viewOriginalProblem, setViewOriginalProblem] = useState(false);
    const [viewDraftProblem, setViewDraftProblem] = useState(false);

    const [isGeneratingImage, setIsGeneratingImage] = useState(false);
    const [generatedImage, setGeneratedImage] = useState(null);
    const [generateImageModal, setGenerateImageModal] = useState(false);
    const [imageSavedCheck, setImageSavedCheck] = useState(false);

    const [unsavedEdits, setUnsavedEdits] = useState(false);
    const [submitAbility, setSubmitAbility] = useState(false);

    const [regenModalOpen, setRegenModalOpen] = useState(false);
    const [regenModalStage, setRegenModalStage] = useState(1)
    const [regenNotes, setRegenNotes] = useState({
        notes: '',
        selectedItems: [],
        question: {},
    });

    const [markedQuestionModal, setMarkedQuestionModal] = useState(false);

    useEffect(() => {
        const noChange = Object.keys(savedProblemDict).length === 0
        if (noChange) {
            setSubmitAbility(false);
        } else {
            setSubmitAbility(true);
        }
    }, [savedProblemDict])

    useEffect(() => {
        let newEditableProblem = null;

        // First check if the problemIx is in savedProblemDict, then check problems
        if (problemIx in savedProblemDict) {
            newEditableProblem = savedProblemDict[problemIx];
        } else if (problems && existingProblems) {
            newEditableProblem = problems[problemIx];
        }

        // Finally, set the state only if newEditableProblem is not null
        if (newEditableProblem !== null) {
            setEditableProblem(newEditableProblem);
        }

        // Reset regen notes
        setRegenNotes({
            notes: '',
            selectedItems: [],
            question: {},
        });

    }, [existingProblems, problemIx]);  // List all dependencies


    const handleNavigation = (newIndex) => {
        // Set the updated question index
        const noChange = areObjectsEqual(problems[problemIx], editableProblem)
        const editsSaved = areObjectsEqual(savedProblemDict[problemIx], editableProblem)
        const savedEdits = (noChange || editsSaved || markedForReeval?.rejected[problemIx])

        setUnsavedEdits(!savedEdits)

        if (savedEdits) {
            setProblemIx(newIndex);
        }
    };

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true)
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/save-generative-questions`,
                dataToSend: {
                    userEmail: user.email,
                    draftId: selection.draftId,
                    questions: savedProblemDict,
                    editMode: selection.reviewType,
                    markedQuestions: markedForReeval,
                },
            });
            navigate("/")
            setIsSubmitting(false)
        } catch (error) {
            console.log(error.message);
        }
    }

    const generateImage = async () => {
        try {
            setGenerateImageModal(true);
            setIsGeneratingImage(true);
            setGeneratedImage(null);
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/generate-image`,
                dataToSend: {
                    userEmail: user.email,
                    draftId: selection.draftId,
                    question: editableProblem,
                    editMode: selection.reviewType,
                },
            });
            setGeneratedImage(response)
            setIsGeneratingImage(false);
        } catch (error) {
            console.log(error.message);
        }
    }

    const encodeImage = async () => {
        try {
            const image_url = generatedImage.s3_img_path
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/encode-image`,
                dataToSend: {
                    userEmail: user.email,
                    image_url: image_url,
                },
            });
            setEditableProblem({
                ...editableProblem,
                ["image_url"]: image_url,
                ["image_url_encoded"]: response.image_url_encoded,
            })
        } catch (error) {
            console.log(error.message)
        }
        setGenerateImageModal(false)
    }

    const regenQuestion = async () => {
        setRegenModalStage(2); // switch to generating
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/regenerate-question`,
                dataToSend: {
                    question: editableProblem,
                    draftId: selection.draftId,
                    regenNotes: regenNotes,
                },
            });
            if (response.success) {
                setRegenModalOpen(false);
                const new_question = response.new_question
                setEditableProblem(new_question)
                setProblems({...problems, [problemIx]: new_question})
            }

        } catch (error) {
            console.log(error.message)
        }
        setRegenModalStage(1);
    }


    const resetEdits = () => {
        setEditableProblem(problems[problemIx])
        delete savedProblemDict[problemIx];
    }

    const saveEdits = () => {
        if (editableProblem.image_url === "TO FILL" || editableProblem.image_url_encoded === "TO FILL") {
            setImageSavedCheck(true);
        } else {
            setSavedProblemDict({...savedProblemDict, [problemIx]: editableProblem})
            setUnsavedEdits(false);
        }
    }

    const handleMarkForReeval = (markedType) => {
        // Determine the action first
        let unmark = problemIx in markedForReeval[markedType];
        // Update the state
        setMarkedForReeval(prev => {
            const updatedMarked = {
                ...prev,
                [markedType]: {...prev[markedType]}
            };

            if (unmark) {
                delete updatedMarked[markedType][problemIx];
            } else {
                const oppType = markedType === "accepted" ? "rejected" : "accepted"
                if (problemIx in markedForReeval[oppType]){
                    delete updatedMarked[oppType][problemIx];
                }
                updatedMarked[markedType][problemIx] = true;
            }
            return updatedMarked;
        });

        // Save edits
        if (!unmark) {
            saveEdits();
        } else {
            if (problemIx in savedProblemDict) {
                setEditableProblem(savedProblemDict[problemIx])
                delete savedProblemDict[problemIx]
            }
        }
    };

    const handleMarkedQuestion = (markedType) => {
        handleMarkForReeval(markedType);
        setMarkedQuestionModal(false);
    }

    const handleMarkedQuestionModal = () => {
        const category = Object.keys(markedForReeval).find(key => markedForReeval[key][problemIx]);
        if (category) {
            handleMarkForReeval(category);
        } else  {
            setMarkedQuestionModal(true)
        }
    }

    // Helper function to dynamically adjust textarea height
    const adjustTextareaHeight = (target) => {
        target.style.height = 'auto';  // Reset height
        target.style.height = target.scrollHeight + 'px';  // Set height based on content
    };

    // Use the useEffect hook to adjust all textareas on initial load
    useEffect(() => {
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach(textarea => {
            adjustTextareaHeight(textarea);
        });
    }, [editableProblem]);


    if (!problems || !editableProblem || !existingProblems) {
        return (
            <div>Loading problems...</div>
        )
    }

    if (isSubmitting) {
        return (
            <div>
                Submitting... You will be taken to the home page after
                the submission is processed.
            </div>
        )
    }


    return (
        <div className="desktop-homepage">
            <>
                <div style={{ display: 'flex', justifyContent: "center", // justifyContent: 'space-between',
                    gap: "50px", margin: "30px", marginBottom: "300px"}}>
                    {viewOriginalProblem  && (
                        <div style={{width: '25%'}}>
                            <div style={{textAlign: "center", fontSize: "24px", marginBottom: "5px"}}>
                                Original Problem
                            </div>
                            <div style={{textAlign: "center", fontSize: "16px", marginBottom: "30px", whiteSpace: 'pre-line' }}>
                                {`Question ID: ${existingProblems[problemIx].question_id}; Category: ${existingProblems[problemIx].category};
                                Difficulty: ${existingProblems[problemIx].question_difficulty}`}
                            </div>
                            {rawFormatting ? (
                                returnFormattedProblem(existingProblems[problemIx], false)
                            ) : (
                                <MathJaxWrapper>
                                    {returnFormattedProblem(existingProblems[problemIx], true)}
                                </MathJaxWrapper>
                            )}
                        </div>
                    )}
                    <div style={{width: "25%"}}>
                        <div style={{textAlign: "center", fontSize: "24px",
                            marginBottom: "70px"}}>
                            {selection.reviewType === "review" ? "Draft Question, Formatted" : "Reviewed Question, Formatted"}
                        </div>
                        <MathJaxWrapper>
                            {returnFormattedProblem(editableProblem, true)}
                        </MathJaxWrapper>
                    </div>
                    <EditableProblem
                        problem={editableProblem}
                        setProblem={setEditableProblem}
                        reviewType={selection.reviewType}
                    />
                    <ImageGenerator
                      editableProblem={editableProblem}
                      setEditableProblem={setEditableProblem}
                      generateImage={generateImage}
                    />
                </div>
                <div className="bottom-section">
                    <button className="nav-button" onClick={resetEdits}>
                        Reset Edits
                    </button>
                    {(selection.reviewType === "review") ? (
                        <>
                            <button className="nav-button" onClick={() => setViewOriginalProblem(!viewOriginalProblem)}>
                                {viewOriginalProblem ? "Hide" : "View "} Original Problem
                            </button>
                            <button className="nav-button" onClick={() => setRawFormatting(!rawFormatting)}>
                                {rawFormatting ? "Hide" : "View"} Original Problem Raw Text
                            </button>
                        </>
                    ) : (
                        <>
                            <button className="nav-button" onClick={() => setViewDraftProblem(true)}>
                                View Draft Problem
                            </button>
                        </>)
                    }
                    <div style={{ flex: 1 }}>
                        <NavigationConsole
                            navBarIndices={navBarIndices}
                            currentQuestion={problemIx}
                            onSelectQuestion={handleNavigation}
                            isVisible={isNavConsoleVisible}
                            toggleVisibility={() => setIsNavConsoleVisible(!isNavConsoleVisible)}
                            answeredQuestions={savedProblemDict}
                            handleSubmit={handleSubmit}
                            markForReview={markedForReeval}
                        />
                    </div>
                    {(selection.reviewType === "finalize") ? (
                        <button className="nav-button" onClick={handleMarkedQuestionModal} style={{width: "150px"}}>
                            {markedForReeval?.accepted[problemIx] || markedForReeval?.rejected[problemIx] ? "Unmark from Reeval" : "Mark for Reeval"}
                        </button>
                    ):(
                        <button className="nav-button" onClick={() => setRegenModalOpen(!regenModalOpen)} style={{width: "150px"}}>
                            Regenerate
                        </button>
                    )}
                    <button className="nav-button" onClick={saveEdits} style={{width: "150px"}}>
                        Save Problem
                    </button>
                    <button
                        className={`nav-button ${submitAbility ? '' : 'disabled'}`}
                        onClick={submitAbility ? handleSubmit : null}
                        disabled={!submitAbility}
                    >
                        Submit Review
                    </button>
                </div>
            </>
            <ImageModal
                isOpen={generateImageModal}
                imgData={generatedImage}
                onConfirm={encodeImage}
                onClose={() => setGenerateImageModal(false)}
            />
            <DraftQuestionModal
                isOpen={viewDraftProblem}
                imgData={generatedImage}
                draftProblems={draftProblems}
                problemIx={problemIx}
                onClose={() => setViewDraftProblem(false)}
            />
            <GenericModal
                isOpen={unsavedEdits}
                onConfirm={saveEdits}
                onClose={() => setUnsavedEdits(false)}
                message={"Warning: You have unsaved edits for this problem."}
                confirmMessage={"Save Edits"}
                declineMessage={"Return to Problem"}
            />
            <GenericModal
                isOpen={imageSavedCheck}
                onConfirm={() => setImageSavedCheck(false)}
                message={"Warning: You have not yet saved an image for this problem."}
                confirmMessage={"Return to Problem"}
            />
            <GenericModal
                isOpen={regenModalOpen}
                onConfirm={regenModalStage === 1 ? regenQuestion : null}
                onClose={() => setRegenModalOpen(!regenModalOpen)}
                message={regenModalStage === 1 ? "Why should this question be regenerated?" : "Generating new question... this can take up to a minute."}
                confirmMessage={regenModalStage === 1 ? "Regenerate question" : ""}
                declineMessage="Cancel"
                showActions={regenModalStage === 1}
            >
                {regenModalStage === 1 && (
                    <BulletListWithNotes
                        regenNotes={regenNotes}
                        setRegenNotes={setRegenNotes}
                    />
                )}
            </GenericModal>
            <GenericModal
                isOpen={markedQuestionModal}
                onConfirm={() => {
                    if (editableProblem?.finalize_comments !== "") {
                        handleMarkedQuestion("accepted");
                    } else {
                        setMarkedQuestionModal(false);
                    }
                }}
                onClose={
                    editableProblem?.finalize_comments !== ""
                        ? () => handleMarkedQuestion("rejected")
                        : null
                }
                message={editableProblem?.finalize_comments !== "" ? (
                   "Do you want to finalize this question or send it back to the reviewer?"
                ) : (
                    "You must enter finalize comments before marking for reevaluation."
                )}
                confirmMessage={editableProblem?.finalize_comments !== "" ? "Finalize" : "Go Back"}
                declineMessage={"Send Back"}
            />
        </div>
    );
}
export default ProblemReview;
