import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';

window.addEventListener('error', (event) => {
  // Prevent default behavior
  event.preventDefault();
  // Navigate to a custom error page or set global error state
});

window.addEventListener('unhandledrejection', (event) => {
  // Prevent default behavior
  event.preventDefault();
  // Navigate to a custom error page or set global error state
});

const root = createRoot(document.getElementById('root'));

root.render(
  <Auth0Provider
    domain="auth0.platform.aliaeducation.com"
    clientId="TQn5yos8s6SeNwFtOydPoz5PS4V21oHW"
    redirectUri={window.location.origin}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Auth0Provider>
);