import React, { useState, useEffect, useRef } from 'react';

const SearchableDropdown = ({ options, value, onChange, name }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const dropdownRef = useRef(null);

    const filteredOptions = options
        .filter(option => option.toLowerCase().startsWith(searchTerm.toLowerCase()))
        .slice(0, 10);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setIsOpen(true);
    };

    const handleOptionClick = (option) => {
        onChange({ target: { name: "questionId", value: option } });
        setSearchTerm(option);
        setIsOpen(false);
    };

    const listStyle = {
        position: 'relative',
        width: '100%',
        maxHeight: '200px',
        overflowY: 'auto',
        border: '1px solid #ccc',
        borderTop: 'none',
        borderRadius: '0 0 4px 4px',
        backgroundColor: 'white',
        zIndex: 1,
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    };

    return (
        <div className={"select-menu"} ref={dropdownRef}>
            <input
                type="text"
                value={searchTerm}
                onChange={handleInputChange}
                onClick={() => setIsOpen(true)}
                className={"select-style"}
                placeholder="Search..."
            />
            {isOpen && (
                <ul className="drop-down-list">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => handleOptionClick(option)}
                            className="list-item-style"
                            onMouseEnter={(e) => e.target.style.backgroundColor = '#f0f0f0'}
                            onMouseLeave={(e) => e.target.style.backgroundColor = 'transparent'}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchableDropdown;