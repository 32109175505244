// src/components/MathJaxWrapper.js
import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const MathJaxWrapper = ({ children }) => {
	const mathJaxConfig = {
	    loader: { load: ['input/tex', 'output/svg'] },
	    tex: {
			inlineMath: [['$', '$'], ['\\(', '\\)']],
	        displayMath: [['$$', '$$'], ['\\[', '\\]']],
	        processEscapes: true,
	        processEnvironments: true,
		    macros: {
                degree: "{\\large ^\\circ}"
            }
	    },
	    svg: {
	        fontCache: 'global'
	    },
	    options: {
	        fastPreview: true,
	        enableMenu: false
	    },
	};

  return (
      <MathJaxContext config={mathJaxConfig} version={3}>
	      <MathJax>
		      {children}
		  </MathJax>
      </MathJaxContext>
  );
};

export default MathJaxWrapper;
