import "./Modal.css"
import React from "react";
import generative_text_formatting from '../assets/generative_text_formatting.svg' // Import the image

const FormattingModal = ({ isOpen, onClose }) => {

    if (!isOpen) return null;

    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <div className="image-container" >
                    <img src={generative_text_formatting}
                         style={{maxHeight: "500px", height: "500px"}}
                         alt="Question Image"
                    />
                </div>
                <div className="modal-actions center-actions">
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    );
};

export default FormattingModal