import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import HomePage from './components/HomePage';
import ProblemParent from "./components/ProblemParent";

function App() {
    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, isLoading]);


    if (isLoading || !isAuthenticated) {
        return <div>Loading...</div>;
    }


    return (
            <Routes>
                <Route path="/" element={<HomePage set/>} />
                <Route path="/problem-review" element={<ProblemParent />} />
            </Routes>
    );
}

export default App;
