import {useAuth0} from "@auth0/auth0-react";
import {useLocation, useNavigate} from "react-router-dom";
import GenericAsync from "../backend/GenericAsync";
import React, {useEffect, useState} from "react";
import ProblemReview from "./ProblemReview";
import ProblemReeval from "./ProblemReeval";
import FormattingModal from "../modals/FormattingModal";

const ProblemParent = () => {

	const backendUrl = process.env.REACT_APP_BACKEND_URL;
	const {user} = useAuth0();
	const location = useLocation();
	const {selection} = location.state || {};

	const [problems, setProblems] = useState(null);
    const [existingProblems, setExistingProblems] = useState(null);
    const [draftProblems, setDraftProblems] = useState(null);
    const [navBarIndices, setNavBarIndices] = useState({});

	const [formattingImageVisible, setFormattingImageVisible] = useState(false);

	const SaveExitButton = () => {
        const navigate = useNavigate();

        const handleSaveAndExit = () => {
            navigate('/'); // Navigate to the Home Page
        };

        return (
            <button onClick={handleSaveAndExit}>Exit (No Save)</button>
        );
    };

	const handleFormattingButton = () => {
        setFormattingImageVisible(true);
    }

	const getDraft = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/get-generative-draft`,
                dataToSend: {
                    userEmail: user.email,
                    draftId: selection.draftId,
                    editMode: selection.reviewType,
                },
            });
            setProblems(response.generated);
            setExistingProblems(response.existing);
			setDraftProblems(response.draft)
            setNavBarIndices(Array.from({ length: response.generated.length}, (_, i) => i + 1))

        } catch (error) {
            console.log(error.message);
        }
    }

	const onCloseFormatting = () => {
        setFormattingImageVisible(false)
    }

	useEffect(() => {
        if (user.email) {
            getDraft();
        }
    }, [user.email]);

	function renderProblemPage (selection) {
		if (!problems) return <div>Loading...</div>;

		if (selection.reviewType !== "reeval") {
			return (
				<ProblemReview
					problems={problems}
                    setProblems={setProblems}
					existingProblems={existingProblems}
					draftProblems={draftProblems}
					navBarIndices={navBarIndices}
					selection={selection}
				/>
			)
		} else {
			return (
				<ProblemReeval
					problems={problems}
					existingProblems={existingProblems}
					navBarIndices={navBarIndices}
					selection={selection}
				/>
			)
		}
	}

	return (
		 <>
            <div className="top-bar">
                <div style={{fontSize: "16px"}}>
                    Edit Mode: {selection.reviewType.toUpperCase()}
                </div>
                <div className="top-right-tools">
                    <button onClick={handleFormattingButton}>
                        View Formatting Tips
                    </button>
                    <SaveExitButton/>
                </div>
            </div>
			{renderProblemPage(selection)}
			 <FormattingModal
				isOpen={formattingImageVisible}
				onClose={onCloseFormatting}
			/>
		</>
	)

}

export default ProblemParent