import React, {useEffect, useState} from 'react';
import '../css/Style.css';
import GenericAsync from "../backend/GenericAsync";
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import LogoutButton from "../common/LogoutButton";
import GenericModal from "../modals/GenericModal";
import SearchableDropdown from "../utils/SearchableDropdown";


const HomePage = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const {user} = useAuth0();
    const [reviewInfo, setReviewInfo] = useState(null)
    const navigate = useNavigate();
    const [reevalPresent, setReevalPresent] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("Processing...");

    const adminEmails = [
        "nrish07@gmail.com", "terence.m.conlon@gmail.com", "alibed@stanford.edu"
    ]
    const allEmails = [
        "alexsoto@stanford.edu", "alibed@stanford.edu", "caitlin.athenarose@gmail.com",
        "pambriz@stanford.edu", "robertkopliku@gmail.com", "nrish07@gmail.com",
        "terence.m.conlon@gmail.com",
    ]

    const selectionDefault = {
        reviewType: "",
        draftId: "",
        category: "",
        difficulty: "",
        newMappings: "",
        assignEmail: "",
        questionId: "",
    }
    const [selection, setSelection] = useState(selectionDefault);

    const loginWorkflow = async () => {
        try {
            const response = await GenericAsync({
                backendUrl: `${backendUrl}/api/login-workflow`,
                dataToSend: {
                    userEmail: user.email,
                },
            });
            setReviewInfo(response);

            if ("reeval" in response && Object.keys(response.reeval).length > 0) {
                setReevalPresent(true);
            }

        } catch (error) {
            console.log(error.message);
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setSelection({ ...selection, [name]: value });
    };

    function reviewOptions(reviewType) {
        const reviewFiles = reviewInfo[reviewType];

        return reviewFiles.map(function(draft) {
            const draftInfo = draft.split("/").reverse();
            const [, subPath,] = draftInfo;
            const userEmail = draftInfo[2]

            if (reviewType === "review" || reviewType === "assign" || reviewType === "reeval") {
                return <option key={draft}
                               value={draft}>{subPath.replaceAll("_", " ")}</option>;
            } else if (reviewType === "finalize") {
                return <option key={draft}
                               value={draft}>{subPath.replaceAll("_", " ")} ({userEmail})</option>;
            }
        });
    }

    useEffect(() => {
        if (user.email) {
            loginWorkflow();
        }
    }, []);

    const handleGoToTest = () => {
        navigate("/problem-review", {state: {selection}});
    };

    if (!user.email || !reviewInfo){
        return <div>Loading...</div>
    }

    const assignQuestionSet = async () => {
        setAssignModalOpen(true);
        try {
            await GenericAsync({
                backendUrl: `${backendUrl}/api/assign-question-set`,
                dataToSend: {
                    userEmail: user.email,
                    selection: selection
                },
            });
            const draftInfo = selection.draftId.split("/").reverse();
            const [, subPath,] = draftInfo;

            setModalMessage(`Question set ${subPath} assigned to ${selection.assignEmail}`)
            setSelection(selectionDefault)
            await loginWorkflow();
        } catch (error) {
            setModalMessage(error.message)
        }
    }

    function handleSelectedDraft() {
        if (selection.reviewType === "review" || selection.reviewType === "finalize" || selection.reviewType === "reeval"){
            return (
                <div>
                    <button
                        className="orange-button"
                        style={{width: "300px"}}
                        onClick={handleGoToTest}
                    >
                        GO TO QUESTION SET
                    </button>
                </div>
            )
        } else {
            return (
                <>
                    <div className={"select-menu"}>
                        <select
                            name="assignEmail"
                            value={selection.assignEmail}
                            onChange={handleChange}
                            className="select-style"
                        >
                            <option value="" disabled>Select Assignee</option>
                            {allEmails.map((email) => (
                                <option key={email} value={email}>
                                    {email}
                                </option>
                            ))}
                        </select>
                    </div>
                    {selection.assignEmail && (
                        <div>
                            <button
                                className="orange-button"
                                style={{width: "300px"}}
                                onClick={assignQuestionSet}
                            >
                                Assign Question Set
                            </button>
                        </div>
                    )}
                </>
            )
        }
    }

    function renderSecondDropDown() {
        if (selection.reviewType === "update") {
            return (
                <SearchableDropdown
                    options={reviewInfo?.question_ids}
                    value={selection.questionId}
                    onChange={handleChange}
                    name="questionId"
                />
            )
        } else {
            return(
             <>
                <div className={"select-menu"}>
                    <select
                        name="draftId"
                        value={selection.draftId}
                        onChange={handleChange}
                        className="select-style"
                    >
                        <option value="" disabled>Select Question Set</option>
                        {reviewOptions(selection.reviewType)}
                    </select>
                </div>
                {(selection.draftId) && (
                    handleSelectedDraft()
                )}
            </>
        )}
    }

    function renderDropDown(reviewInfo, reevalPresent) {
        if (!reviewInfo) return;

        return (
            <>
                <div className={"select-menu"}>
                    <select
                        name="reviewType"
                        value={selection.reviewType}
                        onChange={handleChange}
                        className="select-style"
                    >
                        <option value="" disabled>Review Type</option>
                        {reevalPresent ? (
                            <option value="reeval">Reevaluate</option>
                        ):(
                            <>
                                <option value="review">Review New Draft</option>
                                <option value="finalize">Finalize Review</option>
                                {adminEmails.includes(user.email) && (
                                    <>
                                        {/*<option value="update">Update Current Question</option>*/}
                                        <option value="assign">Assign Draft</option>
                                    </>
                                )}
                            </>
                        )}
                    </select>
                </div>
                {selection.reviewType && (
                   renderSecondDropDown()
                )}
            </>
        )
    }

    return (
        <div className="desktop-homepage">
            <div className="header">
                <LogoutButton />
            </div>
            <div className="homepage-content">
                <div className="welcome-text">
                    Welcome back, {user.name.substring(0, user.name.indexOf(' '))}!
                </div>
                <div className="dropdown-container">
                    {renderDropDown(reviewInfo, reevalPresent)}
                </div>
                <GenericModal
                    isOpen={assignModalOpen}
                    onConfirm={() => setAssignModalOpen(false)}
                    message={"Alia Generative"}
                    children={modalMessage}
                    confirmMessage={"Go Back"}
                />
            </div>
        </div>
    )
}
export default HomePage;
