import React from 'react';
import MathJaxWrapper from './MathJaxWrapper'; // Assuming this component exists
import {MemoizedRenderText} from './TextRender'; // Assuming this component exists

const EditableProblem = ({ problem, setProblem, reviewType }) => {
    const handleChange = (field, value) => {
        setProblem({ ...problem, [field]: value });
    };

    const renderAnswerOptions = () => {
        if (problem.question_format === "radio") {
            return ['A', 'B', 'C', 'D'].map((option) => (
                <div key={option}>
                    {option}) <textarea
                    style={{ width: '100%', height: '50px', marginBottom: "20px" }}
                    value={problem[`answer_${option}`]}
                    onChange={(e) => handleChange(`answer_${option}`, e.target.value)}
                />
                </div>
            ));
        }
        return null;
    };

    return (
        <div style={{ width: '25%' }}>
            <div style={{ textAlign: "center", fontSize: "24px", marginBottom: "70px" }}>
                {reviewType === "review" ? "Draft Question, Editable" : "Reviewed Question, Editable"}
            </div>
            <div>
                Prompt: <textarea
                style={{ width: '100%', height: '200px', marginBottom: "10px" }}
                value={problem.prompt}
                onChange={(e) => handleChange('prompt', e.target.value)}
            />
                Question: <textarea
                style={{ width: '100%', height: '100px', marginBottom: "10px" }}
                value={problem.question}
                onChange={(e) => handleChange('question', e.target.value)}
            />
                {renderAnswerOptions()}
                {problem.question_format === "radio" ? (
                    <div>
                        Correct answer: <textarea
                        style={{ width: '100%', height: '50px', marginBottom: "20px" }}
                        value={problem.correct_radio_answer}
                        onChange={(e) => handleChange('correct_radio_answer', e.target.value)}
                    />
                    </div>
                ) : (
                    <div>
                        Correct answer: <textarea
                        style={{ width: '100%', height: '50px', marginBottom: "20px" }}
                        value={problem.correct_numeric_answer}
                        onChange={(e) => handleChange('correct_numeric_answer', e.target.value)}
                    />
                    </div>
                )}
                <div>
                    Explanation:
                    <div style={{ width: '100%', marginBottom: "20px" }}>
                        <MathJaxWrapper>
                            <MemoizedRenderText text={problem.explanation} />
                        </MathJaxWrapper>
                    </div>
                </div>
                <div>
                    Reviewer comments: <textarea
                    style={{ width: '100%', height: '50px', marginBottom: "20px" }}
                    value={problem.comments}
                    onChange={(e) => handleChange('comments', e.target.value)}
                />
                </div>
                {reviewType !== "review" && (
                    <div>
                        Finalizer comments: <textarea
                        style={{ width: '100%', height: '50px', marginBottom: "20px" }}
                        value={problem.finalize_comments}
                        onChange={(e) => handleChange('finalize_comments', e.target.value)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default EditableProblem;