// src/components/LogoutButton.js
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const LogoutButton = () => {
    const { logout } = useAuth0();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        logout({logoutParams: {returnTo: window.location.origin }});
    };
    return (
        <button className="logout-button" onClick={handleLogout}>
            Log Out
        </button>
    );
};

export default LogoutButton;