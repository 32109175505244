// src/components/NavigationConsole.js
import React, {useState, useEffect} from 'react';
import '../css/Style.css'; // Ensure this path is correct

const NavigationConsole = ({
    navBarIndices,
    currentQuestion,
    onSelectQuestion,
    isVisible,
    toggleVisibility,
    answeredQuestions,
    markForReview,
}) => {

    const [columns, setColumns] = useState(10);

    useEffect(() => {
        let columnCount;
        if (navBarIndices.length <= 30) {
            columnCount = 10;
        } else if (navBarIndices.length <= 60) {
            columnCount = 15;
        } else {
            columnCount = 20;
        }
        setColumns(columnCount);
    }, [navBarIndices.length]);

    return (
           <div className="navigation-console-wrapper"> {/* Use wrapperClass here to apply the correct CSS class */}
                <button className="navigation-console-toggle" onClick={toggleVisibility}>
                   {
                       `Question ${currentQuestion + 1} of ${navBarIndices.length}`
                   }
               </button>
               {isVisible && (
                   <>
                       <div className="navigation-console" style={{ gridTemplateColumns: `repeat(${columns}, minmax(40px, 1fr))` }}>
                           {navBarIndices.map((value, index) => (
                               <button
                                   key={index}
                                   className={`
                                       nav-button
                                       ${index === currentQuestion ? 'current' : ''}
                                       ${(String(index) in answeredQuestions && !markForReview?.rejected[index])? 'answered' : ''}
                                       ${markForReview?.accepted[index] ? 'marked-for-review-accept' : ''}
                                       ${markForReview?.rejected[index] ? 'marked-for-review-reject' : ''}
                                   `}
                                   onClick={() => onSelectQuestion(index)}
                               >
                                   {value}
                               </button>
                           ))}
                       </div>
                   </>
               )}
           </div>
       );
   };

   export default NavigationConsole;
